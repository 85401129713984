import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faFileVideo } from '@fortawesome/free-solid-svg-icons/faFileVideo';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons/faFileAudio';
import { faFileImage } from '@fortawesome/free-solid-svg-icons/faFileImage';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons/faFileArchive';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';

export const ICON_VIDEO = <FontAwesomeIcon icon = {faVideo}/>;

export const ICON_IMAGE = <FontAwesomeIcon icon = {faImage}/>;

export const ICON_STICKY_NOTE = <FontAwesomeIcon icon = {faStickyNote}/>;

export const ICON_FILTER = <FontAwesomeIcon icon = {faFilter}/>;

export const ICON_FILE_VIDEO = <FontAwesomeIcon icon = {faFileVideo}/>;

export const ICON_FILE_AUDIO = <FontAwesomeIcon icon = {faFileAudio}/>;

export const ICON_FILE_IMAGE = <FontAwesomeIcon icon = {faFileImage}/>;

export const ICON_FILE_ARCHIVE = <FontAwesomeIcon icon = {faFileArchive}/>;

export const ICON_COPY = <FontAwesomeIcon icon = {faCopy}/>;

export const ICON_TIMES = <FontAwesomeIcon icon = {faTimes}/>;

export const ICON_CIRCLE_NOTCH = <FontAwesomeIcon icon = {faCircleNotch}/>;

export const ICON_CARET_UP = <FontAwesomeIcon icon = {faCaretUp}/>;

export const ICON_CARET_DOWN = <FontAwesomeIcon icon = {faCaretDown}/>;

export const ICON_CHECK = <FontAwesomeIcon icon = {faCheck} />;

export const ICON_EDIT = <FontAwesomeIcon icon = {faEdit}/>;

export const ICON_SEARCH = <FontAwesomeIcon icon = {faSearch}/>;

export const ICON_PLUS = <FontAwesomeIcon icon = {faPlus}/>;

export const ICON_CLIPBOARD = <FontAwesomeIcon icon = {faClipboard}/>;

export const ICON_EXCLAIMATION_TRIANGLE = <FontAwesomeIcon icon = {faExclamationTriangle}/>;

export const ICON_ANGLE_LEFT = <FontAwesomeIcon icon = {faAngleLeft}/>;

export const ICON_ANGLE_DOUBLE_LEFT = <FontAwesomeIcon icon = {faAngleDoubleLeft}/>;

export const ICON_ANGLE_RIGHT = <FontAwesomeIcon icon = {faAngleRight}/>;

export const ICON_ANGLE_DOUBLE_RIGHT = <FontAwesomeIcon icon = {faAngleDoubleRight}/>;

export const ICON_CHEVRON_DOWN = <FontAwesomeIcon icon = {faChevronDown}/>;

export const ICON_CHEVRON_UP = <FontAwesomeIcon icon = {faChevronUp}/>;

export const ICON_USER_LOCK = <FontAwesomeIcon icon = {faUserLock}/>;

export const ICON_BAN = <FontAwesomeIcon icon = {faBan}/>;

