import * as React from 'react';
import ImposiumDropdown from '../dropdown/Dropdown';
import ServiceIcon from '../service-icon/ServiceIcon';
import { string2HexCode, parameterizeServiceUrl } from '../../Util';
import { ICON_CHEVRON_DOWN, ICON_CHEVRON_UP} from '../../constants/icons';

export interface IHeaderProps {
    email? : string;
    accessData : any;
    activeOrganization : string;
    activeStory? : string;
    hideStoryPicker? : boolean;
    onOrganizationChange? : (orgId : any) => any;
    onStoryChange? : (story : any) => any;
    logout : (...args) => any;
}

export interface IHeaderState {
    inactiveOrganizations : any[];
    servicesByOrganization : any[];
    activeServiceBlob : any;
    activeOrganizationBlob : any;
    showAccountDropdown : boolean;
    showStoriesDropdown : boolean;
    showServiceDropdown : boolean;
    dropdownToggleColor : string;
    activeFirstChar : string;
}

export default class ImposiumHeader extends React.PureComponent<IHeaderProps, IHeaderState> {
    private static readonly DEFAULT_TITLE : string = 'imposium';
    private static readonly SERVICE_MESH_ALIAS : string = 'services';
    private static readonly DOCS_HEADER : string = 'documentation';
    private static readonly PRIVATE_HEADER : string = 'admin';
    private static readonly API_DOCS_LINK_COPY : string = 'REST API';
    private static readonly SDK_DOCS_LINK_COPY : string = 'Javascript SDK';
    private static readonly API_DOCS_LINK : string = 'https://docs.imposium.com';
    private static readonly SDK_DOCS_LINK : string = 'https://docs.imposium.com/js-sdk/';
    private static readonly DEFAULT_SERVICE_TOGGLE_COPY : string = 'stories';
    private static readonly DEFAULT_ACCOUNT_TOGGLE_COLOR : string = 'royalblue';
    private static readonly NO_STORIES_COPY : string = 'No stories were found';
    private static readonly LOGOUT_COPY : string = 'logout';
    private static readonly UUID_LENGTH : number = 36;
    private static readonly UP : JSX.Element = (ICON_CHEVRON_UP);
    private static readonly DOWN : JSX.Element = (ICON_CHEVRON_DOWN);
    private static readonly PRIVATE_IDS : number[] = [9, 10, 11, 12];

    private readonly serviceToggleRef : any = null;
    private readonly storyToggleRef : any = null;
    private readonly orgsToggleRef : any = null;

    constructor(p : IHeaderProps) {
        super(p);

        this.state = {
            inactiveOrganizations: [],
            servicesByOrganization: [],
            activeServiceBlob: undefined,
            activeOrganizationBlob: undefined,
            showAccountDropdown: false,
            showStoriesDropdown: false,
            showServiceDropdown: false,
            dropdownToggleColor: ImposiumHeader.DEFAULT_ACCOUNT_TOGGLE_COLOR,
            activeFirstChar: ''
        };

        this.serviceToggleRef = React.createRef();
        this.storyToggleRef = React.createRef();
        this.orgsToggleRef = React.createRef();
    }

    public componentDidMount = () : void => {
        const {accessData, activeOrganization} = this.props;

        if (accessData) {
            this.parseAccessData(accessData, activeOrganization);
        }
    }

    public componentDidUpdate = (prevProps : IHeaderProps) : void => {
        const {accessData: currAccess, activeOrganization: currActive} = this.props;
        const {accessData: prevAccess, activeOrganization: prevActive} = prevProps;

        if (currAccess && currAccess !== prevAccess) {
            this.parseAccessData(currAccess, currActive);
        }

        if (
            typeof currActive === 'string' &&
            currActive.length === ImposiumHeader.UUID_LENGTH &&
            currActive !== prevActive
        ) {
            this.parseAccessData(currAccess, currActive);
        }
    }

    private parseAccessData = (accessData : any, activeOrganization : string) : void => {
        const {organizations, services} = accessData;

        const activeOrganizationBlob : any = (activeOrganization)
            ? accessData.organizations.find((org) => (org.id === activeOrganization))
            : undefined;

        const inactiveOrganizations : any[] = (organizations.length > 1)
            ? organizations.filter((o) => (o.id !== activeOrganization))
            : [];

        const servicesByOrganization : any[] = (services && activeOrganizationBlob)
            ? services.filter((s : any) => (activeOrganizationBlob.services.includes(s.id)))
            : [];

        const activeServiceBlob : any = (servicesByOrganization.length > 1)
            ? services.find((s : any) => (window.location.href.includes(s.url)))
            : undefined;

        let dropdownToggleColor : string = ImposiumHeader.DEFAULT_ACCOUNT_TOGGLE_COLOR;
        let activeFirstChar : string = '';

        if (activeOrganizationBlob && activeOrganizationBlob.hasOwnProperty('name')) {
            dropdownToggleColor = string2HexCode(activeOrganizationBlob.name);
            activeFirstChar = this.pullFirstChar(activeOrganizationBlob.name);
        }

        this.setState({
            servicesByOrganization,
            activeServiceBlob,
            inactiveOrganizations,
            activeOrganizationBlob,
            dropdownToggleColor,
            activeFirstChar
        });
    }

    private changeStory = (story : any) : void => {
        const {onStoryChange} = this.props;

        if (typeof onStoryChange === 'function') {
            onStoryChange(story);
            this.setState({showStoriesDropdown: false});
        }
    }

    private toggleAccountDropdown = (toggle : boolean, newId : string = '') : void => {
        const {onOrganizationChange} = this.props;

        if (toggle) {
            this.setState({showAccountDropdown: true});
        } else {
            this.setState({showAccountDropdown: false});

            // Propagate handler if supplied (more useful in react / redux flow)
            if (newId && onOrganizationChange) {
                onOrganizationChange(newId);
            }

            // V1 Default behavior - redirect to hub on org change
            if (newId && !onOrganizationChange) {
                window.location.replace(`${this.getHomeUrl()}?organization_id=${newId}`);
            }
        }
    }

    private getHomeUrl = () : string => {
        const {location: {hostname, host}} = window;

        if (hostname === 'localhost') {
            return 'http://localhost:3036';
        } else if (host.includes('.staging.')) {
            return 'https://launchpad.staging.imposium.com';
        } else {
            return 'https://launchpad.imposium.com';
        }
    }

    private doLogout = () : void => {
        this.setState({showAccountDropdown: false});
        this.props.logout();
    }

    private pullFirstChar = (s : string) : string => {
        return (s) ? s.substring(0, 1) : '';
    }

    private renderDropdownChevron = (toggle : boolean) : JSX.Element => {
        return (toggle) ? ImposiumHeader.UP : ImposiumHeader.DOWN;
    }

    private renderServiceAnchor = (s : any, activeOrganization : string, activeStory : string) : JSX.Element => (
        <div className = 'anchor-wrapper' key = {s.id}>
            <a href = {parameterizeServiceUrl(activeOrganization, activeStory, s.url, s.id)}>
                <div className = 'icon-fixed'>
                    <ServiceIcon iconName = {s.icon} color = {`#${s.color}`} />
                </div>
                {s.name}
            </a>
        </div>
    )

    public render() {
        const {email, accessData, activeOrganization, activeStory, hideStoryPicker} = this.props;

        const {
            showAccountDropdown, servicesByOrganization, activeServiceBlob,
            showStoriesDropdown, inactiveOrganizations, activeOrganizationBlob,
            activeFirstChar, dropdownToggleColor, showServiceDropdown
        } = this.state;

        let currentStory : any;
        let headerBorder : any;
        let activeService : any;
        let serviceDropdownColor : any = {};
        let serviceAnchors : JSX.Element[];
        let privateAnchors : JSX.Element[];
        let accountDropdownToggle : JSX.Element;
        let activePanel : JSX.Element;
        let storyToggle : JSX.Element;
        let orgPicker : JSX.Element;
        let storyMenuInner : JSX.Element;
        let serviceDropdownToggle : JSX.Element;
        let serviceDropdownMenu : JSX.Element;

        if (accessData) {
            activeService = servicesByOrganization.find((s : any) => (window.location.href.includes(s.url)));

            if (activeService) {
                serviceDropdownColor = {color: `#${activeService.color}`};
                headerBorder = {borderBottom: `4px solid #${activeService.color}`};
            }

            serviceAnchors = servicesByOrganization
                .filter((s : any) => (!s.private && ImposiumHeader.PRIVATE_IDS.indexOf(s.id) === -1))
                .map((s : any) => this.renderServiceAnchor(s, activeOrganization, activeStory));

            privateAnchors = servicesByOrganization
                .filter((s : any) => (!s.private && ImposiumHeader.PRIVATE_IDS.indexOf(s.id) > -1))
                .map((s : any) => this.renderServiceAnchor(s, activeOrganization, activeStory));

            serviceDropdownMenu = (
                <div className = 'service-menu'>
                    <section className = 'service-menu-section'>
                        <div className = 'service-menu-heading'>
                            {ImposiumHeader.SERVICE_MESH_ALIAS}
                        </div>

                        <div className = 'service-menu-anchors'>
                            {serviceAnchors}
                        </div>
                    </section>

                    {
                        (privateAnchors.length > 0) && (
                            <section className = 'service-menu-section'>
                                <div className = 'service-menu-heading'>
                                    {ImposiumHeader.PRIVATE_HEADER}
                                </div>

                                <div className = 'service-menu-anchors'>
                                    {privateAnchors}
                                </div>
                            </section>
                        )
                    }

                    <section className = 'service-menu-section'>
                        <div className = 'service-menu-heading'>
                            {ImposiumHeader.DOCS_HEADER}
                        </div>

                        <div className = 'service-menu-anchors'>
                            <div className = 'anchor-wrapper doc'>
                                <a target = '__blank' href = {ImposiumHeader.API_DOCS_LINK}>
                                    <div className = 'icon-fixed'><ServiceIcon iconName = 'FaCogs' /></div>
                                    {ImposiumHeader.API_DOCS_LINK_COPY}
                                </a>
                            </div>

                            <div className = 'anchor-wrapper doc'>
                                <a target = '__blank' href = {ImposiumHeader.SDK_DOCS_LINK}>
                                    <div className = 'icon-fixed'><ServiceIcon iconName = 'FaJsSquare' /></div>
                                    {ImposiumHeader.SDK_DOCS_LINK_COPY}
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
            );

            serviceDropdownToggle = (
                <button
                    id = 'btn-toggle-services'
                    className = 'btn-toggle-services'
                    style = {serviceDropdownColor}
                    ref = {this.serviceToggleRef}
                    onClick = {() => this.setState({showServiceDropdown : !this.state.showServiceDropdown})}>

                    <span>{(activeServiceBlob) ? activeServiceBlob.name : ImposiumHeader.SERVICE_MESH_ALIAS}</span>
                    {this.renderDropdownChevron(showServiceDropdown)}
                </button>
            );

            accountDropdownToggle = (
                <button
                    id = 'btn-toggle-orgs'
                    className = 'btn-toggle-orgs'
                    ref = {this.orgsToggleRef}
                    style = {{backgroundColor: dropdownToggleColor}}
                    onClick = {() => this.toggleAccountDropdown(!showAccountDropdown)}>

                    {activeFirstChar}
                </button>
            );
        }

        if (accessData && activeOrganizationBlob) {
            activePanel = (
                <div className = 'orgs-menu-active-org'>
                    <div className = 'active-first-char' style = {{backgroundColor: dropdownToggleColor}}>
                        {this.pullFirstChar(activeOrganizationBlob.name)}
                    </div>

                    <div>{activeOrganizationBlob.name}</div>
                </div>
            );
        }

        if (accessData && activeOrganizationBlob && !hideStoryPicker) {
            currentStory = activeOrganizationBlob.stories.find((s : any) => (s.id === activeStory));

            storyToggle = (
                <button
                    id = 'btn-toggle-story'
                    className = 'btn-toggle-story'
                    ref = {this.storyToggleRef}
                    onClick = {() => {this.setState({showStoriesDropdown: !this.state.showStoriesDropdown}); }}
                >
                    <div className = 'story-name'>
                        {(currentStory) ? currentStory.name : ImposiumHeader.DEFAULT_SERVICE_TOGGLE_COPY}
                    </div>
                    {this.renderDropdownChevron(showStoriesDropdown)}
                </button>
            );

            if (activeOrganizationBlob.stories.length === 0) {
                storyMenuInner = (<div className = 'no-stories-dialog'>{ImposiumHeader.NO_STORIES_COPY}</div>);
            } else {
                storyMenuInner = activeOrganizationBlob.stories.map((s : any) => (
                    <button key = {s.id} className = 'btn-change-story' onClick = {() => this.changeStory(s)}>
                        {s.name}
                    </button>
                ));
            }
        }

        if (showAccountDropdown) {
            orgPicker = (
                <div className = 'orgs-menu-org-picker'>
                    {inactiveOrganizations.map((o) => (
                        <button
                            key = {o.name}
                            className = 'menu-item inactive-org-button'
                            onClick = {() => this.toggleAccountDropdown(false, o.id)}>

                            <div className = 'inactive-first-char' style = {{backgroundColor: string2HexCode(o.name)}}>
                                {this.pullFirstChar(o.name)}
                            </div>

                            {o.name}
                        </button>
                    ))}
                </div>
            );
        }

        return (
            <nav id = 'imposium-header' className = 'imposium-header' style = {headerBorder}>
                <div className = 'inner-content'>
                    <span className = 'inner-left'>
                        <a
                            className = 'title'
                            href = {parameterizeServiceUrl(activeOrganization, activeStory, this.getHomeUrl())}
                        >
                            <div className = 'logo' />
                            <h1 className = 'heading'>{ImposiumHeader.DEFAULT_TITLE}</h1>
                        </a>

                        {serviceDropdownToggle}
                    </span>

                    <span className = 'inner-right'>
                        {storyToggle}
                        {accountDropdownToggle}
                    </span>
                </div>

                <ImposiumDropdown
                    key = 'services-dropdown'
                    position = 'bottomleft'
                    show = {showServiceDropdown}
                    toggleRef = {this.serviceToggleRef}
                    onOutsideClick = {() => this.setState({showServiceDropdown: false})}>

                    {serviceDropdownMenu}
                </ImposiumDropdown>

                <ImposiumDropdown
                    key = 'stories-dropdown'
                    position = 'bottomright'
                    show = {showStoriesDropdown}
                    toggleRef = {this.storyToggleRef}
                    onOutsideClick = {() => this.setState({showStoriesDropdown: false})}>

                    <div className = 'stories-menu'>{storyMenuInner}</div>
                </ImposiumDropdown>

                <ImposiumDropdown
                    key = 'org-dropdown'
                    position = 'bottomright'
                    show = {showAccountDropdown}
                    toggleRef = {this.orgsToggleRef}
                    onOutsideClick = {() => this.toggleAccountDropdown(false)}>

                    <div className = 'orgs-menu'>
                        {activePanel}
                        {orgPicker}

                        <div className = 'orgs-menu-footer'>
                            <div className = 'email'>{email}</div>
                            <button className = 'btn-logout' onClick = {() => this.doLogout()}>
                                {ImposiumHeader.LOGOUT_COPY}
                            </button>
                        </div>
                    </div>
                </ImposiumDropdown>
            </nav>
        );
    }
}
