import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

interface INoAccessProps {
    type : 'request' | 'restrict';
}

const MAIL_TO : string = 'mailto:info@imposium.com?subject=My access to Imposium is restricted';
const REQUEST_ACCESS_TITLE : string = 'You don\'t have access to Imposium yet';
const REQUEST_HREF_COPY : string = 'Request Access';
const RESTRICTED_ACCESS_TITLE : string = 'Service not found';

const NoAccess : React.FC<INoAccessProps> = (p : INoAccessProps) => {
    if (!p.type) {
        throw new Error('NoAccess requires a type of either: request or restrict');
    }

    const isRequest : boolean = (p.type === 'request');

    return (
        <div className = 'no-access'>
            {
                (isRequest)
                    ? <FontAwesomeIcon icon = {faUserLock} size = '6x' />
                    : <FontAwesomeIcon icon = {faExclamationTriangle} size = '6x' />
            }

            <div className = 'no-access-copy'>
                <h1 className = 'no-access-heading'>{(isRequest) ? REQUEST_ACCESS_TITLE : RESTRICTED_ACCESS_TITLE}</h1>
                {(isRequest) && <a href = {MAIL_TO}>{REQUEST_HREF_COPY}</a>}
            </div>
        </div>
    );
};

export default NoAccess;
