import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJsSquare } from '@fortawesome/free-brands-svg-icons/faJsSquare';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
import { faGavel } from '@fortawesome/free-solid-svg-icons/faGavel';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faFileVideo } from '@fortawesome/free-solid-svg-icons/faFileVideo';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSiteMap';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';

interface IServiceIconProps {
    iconName : string;
    color? : string;
}

const ServiceIcon : React.FC<IServiceIconProps> = (p : IServiceIconProps) => {
    switch (p.iconName) {
        case 'FaFilm':
            return (<FontAwesomeIcon icon = {faFilm} color = {p.color} />);
        case 'FaRegFileVideo':
            return (<FontAwesomeIcon icon = {faFileVideo} color = {p.color} />);
        case 'FaChartBar':
            return (<FontAwesomeIcon icon = {faChartBar} color = {p.color} />);
        case 'FaGavel':
            return (<FontAwesomeIcon icon = {faGavel} color = {p.color} />);
        case 'FaDatabase':
            return (<FontAwesomeIcon icon = {faDatabase} color = {p.color} />);
        case 'FaVideo':
            return (<FontAwesomeIcon icon = {faVideo} color = {p.color} />);
        case 'FaUsersCog':
            return (<FontAwesomeIcon icon = {faUsersCog} color = {p.color} />);
        case 'FaPlayCircle':
            return (<FontAwesomeIcon icon = {faPlayCircle} color = {p.color} />);
        case 'FaUserLock':
            return (<FontAwesomeIcon icon = {faUserLock} color = {p.color} />);
        case 'FaSiteMap':
            return (<FontAwesomeIcon icon = {faSitemap} color = {p.color} />);
        case 'FaFileAlt':
            return (<FontAwesomeIcon icon = {faFileAlt} color = {p.color} />);
        case 'FaChartArea':
            return (<FontAwesomeIcon icon = {faChartArea} color = {p.color} />);
        case 'FaCogs':
            return (<FontAwesomeIcon icon = {faCogs} />);
        case 'FaJsSquare':
            return (<FontAwesomeIcon icon = {faJsSquare} />);
        default:
            return null;
    }
};

export default ServiceIcon;
