export const isLocal = () : boolean => ((window.location.hostname === 'localhost'));

export const getActiveOrg = (activeOrgId : string, access : any) : any => (
    access.organizations.find((o : any) => (o.id === activeOrgId))
);

export const validateAccessLevel = (orgId : string, serviceId : number, access : any) : boolean => {
    const activeOrg : any = getActiveOrg(orgId, access);

    if (!activeOrg) {
        return false;
    }

    return activeOrg.services.includes(serviceId);
};

export const getFirstStoryInOrg = (orgId : string, access : any) : string => {
    const activeOrg : any = getActiveOrg(orgId, access);

    return (activeOrg && typeof activeOrg.stories[0] === 'object')
        ? activeOrg.stories[0].id
        : '';
};

export const scrapeEmail = (auth : any) : string => (
    (auth && auth.idTokenPayload && auth.idTokenPayload['https://imposium.com/email'])
        ? auth.idTokenPayload['https://imposium.com/email']
        : ''
);

export const parameterizeServiceUrl = (
    activeOrg : string,
    activeStory : string,
    url : string,
    id? : number
) : string => {
    if (activeOrg && activeStory) {
        switch (id) {
            case 1:
            case 4:
            case 5:
            case 6:
            case 8:
                return `${url}/${activeStory}?organization_id=${activeOrg}`;
            case 3:
                return `${url}/dashboard/live/${activeStory}?organization_id=${activeOrg}`;
            default:
                return `${url}?organization_id=${activeOrg}`;
        }
    } else if (activeOrg && !activeStory) {
        return `${url}?organization_id=${activeOrg}`;
    } else {
        return url;
    }
};

export const string2HexCode = (s : string) : string => {
    const hash : any = s.split('')
        .map((c : string, i : number) => (s.charCodeAt(i)))
        .reduce<number>((p : number, c : number) => (c + ((p << 5) - p)), 0);

    const color : string = (hash & 0x00FFFFFF).toString(16).toUpperCase();

    return `#${'00000'.substring(0, 6 - color.length) + color}`;
};

export const generateUUID = () : string => {

    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
};

export const fitToContainer = (container : any, content : any, config : any) : any => {
    let newH;
    let newW;
    let top;
    let left;
    const aspectRatio = content.width / content.height;
    const contWidth = (config.padding) ? container.width - (config.padding * 2) : container.width;
    const contHeight = (config.padding) ? container.height - (config.padding * 2) : container.height;
    const padding = (config.padding) ? config.padding : 0;
    // scale
    if (config.scaleMode === 'proportionalInside') {

        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH > contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }

    } else if (config.scaleMode === 'proportionalOutside') {

        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH < contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }

    } else if (config.scaleMode === 'none' || !config.scaleMode) {

        newW = content.width;
        newH = content.height;
    }

    if (config.maxWidth) {
        if (newW > config.maxWidth) {
            newW = config.maxWidth;
            newH = newW / aspectRatio;
        }
    }

    if (config.maxHeight) {
        if (newH > config.maxHeight) {
            newH = config.maxHeight;
            newW = newH * aspectRatio;
        }
    }

    // fit
    left = (config.hAlign === 'left') ? 0 : (config.hAlign === 'right') ? (contWidth - newW) : (contWidth - newW) / 2;
    top = (config.vAlign === 'top') ? 0 : (config.vAlign === 'bottom') ? (contHeight - newH) : (contHeight - newH) / 2;

    top += padding;
    left += padding;

    return {
        width: newW,
        height: newH,
        top,
        left
    };
};

// only allow hotkeys to fire on inputs if it's the ENTER or ESCAPE key
export const filterHotkeys = (e) => {

    const tagName = (e.target || e.srcElement).tagName;

    const isInput = (tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA');
    const enter = (e.code === 'Enter');
    const esc = (e.code === 'Escape');

    if (!isInput || isInput && enter || isInput && esc) {
        return true;
    } else {
        return false;
    }
};

export const isObjEmpty = (obj : any) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

export const validateAssetMimetype = (file : File) : boolean => {
    const [fileType, fileFormat] = file.type.split('/');

    switch (fileType) {
        case 'image':
        case 'video':
        case 'audio':
            return true;
        case 'application':
            switch (fileFormat) {
                case 'zip':
                case 'x-gzip':
                    return true;
            }
    }

    console.warn(`Mimetype: ${file.type} is not a supported asset type.`);
    return false;
};

export const mimetypeConformsToOverlay = (overlayAccepts : string, file : File) : boolean => {
    const [fileType, fileFormat] = file.type.split('/');

    return (
        (overlayAccepts === 'video' && fileType === 'video') ||
        (overlayAccepts === 'image' && fileType === 'image') ||
        (overlayAccepts === 'audio' && fileType === 'audio') ||
        (overlayAccepts === 'template' && fileType === 'application' && (fileFormat === 'zip' || fileFormat === 'x-gzip')) ||
        (overlayAccepts === 'image_sequence' && fileType === 'application' && (fileFormat === 'zip' || fileFormat === 'x-gzip'))
    );
};

export const toFixed = (num : number, fixed : number) => {
    const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
};

export const padStart = (num : number, pad : number) => {
    return new Array(pad + 1 - (num + '').length).join('0') + num;
};

export const formatDateDefault = (unix : number) => {

    const date = new Date(unix * 1000);

    const month = date.getMonth() + 1; // JS months are 0 indexed...
    const day = date.getDate();
    const year = date.getFullYear();

    let hr = date.getHours();
    const meridian = hr >= 12 ? 'PM' : 'AM';
    if (hr > 12) { hr = hr - 12; }

    let min : any = date.getMinutes();
    if (min < 10) {
        min = `0${min}`;
    }

    return `${month}/${day}/${year} ${hr}:${min} ${meridian}`;
};

export const formattedTime = (millis : number) => {
    const minutes : any = Math.floor(millis / 60000);
    const seconds : any = ((millis % 60000) / 1000).toFixed(0);

    return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};
