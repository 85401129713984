import * as React from 'react';

export interface IDropdownMenuProps {
    toggleRef : any;
    show : boolean;
    fixed? : boolean;
    children : React.ReactChild;
    position : 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
    onOutsideClick : (...args) => any;
}

export interface IDropdownMenuState {
    build : any;
    offset : any;
}

export default class ImposiumDropdown extends React.PureComponent<IDropdownMenuProps, IDropdownMenuState> {
    private static readonly ESC_CODE : number = 27;

    private resizeHandler : any = () => this.onResize();
    private clickHandler : any = (e) => this.detectOutsideClick(e);
    private escHandler : any = (e) => this.onEsc(e);
    private dropdownRef : any = null;

    constructor(p) {
        super(p);

        this.state = {
            build: false,
            offset: {}
        };

        this.dropdownRef = React.createRef();
    }

    public componentDidUpdate(prevProps : IDropdownMenuProps) {
        if (this.props.show && !prevProps.show) {
            this.onResize();
            this.setState({build: true}, () => {
                window.addEventListener('resize', this.resizeHandler);
                window.addEventListener('mousedown', this.clickHandler);
                window.addEventListener('keydown', this.onEsc);
            });
        }

        if (!this.props.show && prevProps.show) {
            this.cancelRender();
        }
    }

    private onResize = () : void => {
        const {toggleRef} = this.props;

        if (typeof toggleRef !== 'undefined') {
            const {top, left, bottom, right} = toggleRef.current.getBoundingClientRect();
            this.setState({offset: {top, left, bottom, right}});
        }
    }

    private cancelRender = (forced : boolean = false) : void => {
        const {onOutsideClick} = this.props;

        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('mousedown', this.clickHandler);
        window.addEventListener('keydown', this.onEsc);
        this.setState({build: false, offset: {}});

        if (forced) {
            onOutsideClick();
        }
    }

    private onEsc = (e : any) : void => {
        const {onOutsideClick} = this.props;

        if (e.keyCode === ImposiumDropdown.ESC_CODE) {
            this.cancelRender(true);
        }
    }

    private detectOutsideClick = (e : any) : void => {
        const {toggleRef, onOutsideClick} = this.props;
        const {target} = e;

        if (
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(target) &&
            !toggleRef.current.contains(target)
        ) {
            this.cancelRender(true);
        }
    }

    public render() {
        const {build, offset} = this.state;
        const {show, position, children, fixed} = this.props;
        const dropdownStyle : any = {
            position: (fixed) ? 'fixed' : 'absolute',
            transform: (build) ? 'scaleY(1)' : 'scaleY(0)',
            opacity: (build) ? '1' : '0'
        };

        if (!show) {
            return null;
        }

        if (offset) {
            const {documentElement: {clientWidth}} = document;
            const {top, left, bottom, right} = offset;

            switch (position) {
                case 'topleft':
                case 'topright':
                case 'bottomleft':
                    dropdownStyle.top = `${bottom + 5}px`;
                    dropdownStyle.left = `${left}px`;
                    dropdownStyle.transformOrigin = 'top left';

                    break;
                case 'bottomright':
                    dropdownStyle.top = `${bottom + 5}px`;
                    dropdownStyle.right = `${clientWidth - right}px`;
                    dropdownStyle.transformOrigin = 'top right';

                    break;
                default:
                    break;
            }
        }

        return (
            <div className = 'imposium-dropdown' style = {dropdownStyle} ref = {this.dropdownRef}>
                {children}
            </div>
        );
    }
}
